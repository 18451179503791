import EmojiPicker, {
  EmojiClickData,
  SuggestionMode,
} from 'emoji-picker-react';
import { KeyboardEvent, forwardRef, useRef, useState } from 'react';

import Icon, { IconSymbol } from './Icon';

import classNames from 'classnames';
import { inScreenPortion } from 'helpers/helpers';
import { useOnClickOutside } from 'usehooks-ts';
import './EmojisButton.scss';

interface EmojisButtonProps {
  // targetInputRef?: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
  onSelect: (emojiData: EmojiClickData, event: MouseEvent) => void;
  onClose: () => void;
}

const EmojisButton = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  EmojisButtonProps
>(function (props: EmojisButtonProps, ref) {
  const { onSelect, onClose } = props;
  const [open, setOpen] = useState<boolean>(false);
  const pickerRef = useRef<HTMLDivElement>(null);

  function close() {
    setOpen(false);
    if (typeof ref !== 'function' && ref?.current) {
      ref.current.focus();
    }
    if (onClose) onClose();
  }
  function toggle() {
    if (open) close();
    else setOpen(true);
  }

  function handleBlur(e: React.FocusEvent) {
    const tgt = e.relatedTarget;
    if (tgt && pickerRef.current?.contains(tgt)) return;
    if (open) {
      e.stopPropagation();
      close();
    }
  }

  function onClickOutside(e: MouseEvent | TouchEvent) {
    e.stopPropagation();
    if (open) {
      close();
    }
  }
  useOnClickOutside(pickerRef, onClickOutside);

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      e.stopPropagation();
      close();
    }
  }

  function upperScreen(): boolean {
    const element = pickerRef.current;
    if (!element) {
      return false;
    }
    return inScreenPortion(element);
  }

  return (
    <>
      <div
        ref={pickerRef}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        className="emoji_picker_button"
      >
        <Icon
          className="icon_emojis"
          symbol={IconSymbol.smiling}
          onClick={toggle}
          size={18}
          blurBg={true}
        />
        <div
          className={classNames('emoji_picker', {
            emoji_picker_bottom: upperScreen(),
            emoji_picker_closed: !open,
          })}
          onBlur={handleBlur}
        >
          <EmojiPicker
            open={open}
            onEmojiClick={onSelect}
            width={240}
            height={240}
            // searchDisabled={true}
            previewConfig={{
              defaultEmoji: '1f60a',
              defaultCaption: "What's your mood?",
              // showPreview: true,
              showPreview: false,
            }}
            suggestedEmojisMode={SuggestionMode.RECENT}
          />
        </div>
      </div>
    </>
  );
});

export default EmojisButton;
